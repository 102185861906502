button

  &.btn
    padding: 0 var( --gap )

    &.accent
      background: var( --accent )
      color: var( --accent-color )

    &.danger
      background: var( --danger )
      color: var( --danger-color )

    &:disabled
      cursor: not-allowed

  &.highlight
    color: var( --accent )

.scrollable-x
  overflow: auto
  white-space: nowrap
  width: calc( 100vw - var( --gap ) * 2 )

  table
    width: 100%

aside
  & + main
    .scrollable-x
      width: calc( 100vw - var( --aside-size ) - var( --gap ) * 2 )

  &.minified
    & + main
      .scrollable-x
        width: calc( 100vw - var( --header-size ) - var( --gap ) * 2 )

        table
          tr
            td
              max-width: calc( ( 100vw - var( --header-size ) ) * 0.7 )

table
  thead,
  tbody

    tr
      height: var( --header-size )

      td
        padding: 0 var( --gap )
        vertical-align: middle
        max-width: calc( ( 100vw - var( --aside-size ) ) * 0.7 )

        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

        a
          color: var( --color )

        button
          height: var( --header-size )
          width: var( --header-size )

          &:hover
            background: rgba( white, 0.2 )

  thead
    background: var( --table-header-background )
    color: var( --table-header-color )
    border-top-left-radius: var( --border-radius )
    border-top-right-radius: var( --border-radius )

    tr

      td
        font-weight: bold

  tbody

    tr

      &:nth-child( even )
        background: var( --table-row-even )

      &:hover
        background: var( --table-row-hover )

.pager
  background: var( --table-card-background )
  display: flex
  height: var( --header-size )
  line-height: var( --header-size )
  font-size: 95%
  border-bottom-left-radius: var( --border-radius )
  border-bottom-right-radius: var( --border-radius )

  .info
    padding: 0 var( --gap )

  .ipp
    flex: 1
    display: flex
    justify-content: flex-end

    span
      padding: 0 var( --gap )

    select
      appearance: none
      border: none
      padding: 0 var( --gap )
      background: transparent
      color: var( --color )
      cursor: pointer

      &:hover
        background: rgba( white, 0.1 )

  .actions

    button
      width: var( --header-size )
      height: var( --header-size )
      font-size: 140%

      &:disabled
        cursor: not-allowed
        opacity: 0.7

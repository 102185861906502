$size: calc( var( --base ) * 4 )

.filter

  .isbn-list
    gap: 0

    & > div
      height: $size
      line-height: $size

      input
        height: $size

      label
        line-height: $size

      &:hover
        background: var( --table-row-hover )

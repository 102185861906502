.hs
  display: flex
  flex-direction: column
  gap: var( --gap )

  &.center
    justify-content: center

  &.centered
    align-items: center

  &.reverse
    flex-direction: column-reverse

  &.middle
    justify-content: center
    align-items: center

.vs
  display: flex
  flex-direction: row
  gap: var( --gap )

  &.reverse
    flex-direction: row-reverse

  &.middle
    justify-content: center
    align-items: center

.wrap
  flex-wrap: wrap

.zero
  flex: 0

.one
  flex: 1

.two
  flex: 2

.three
  flex: 3

.four
  flex: 4

.five
  flex: 5

.six
  flex: 6

.seven
  flex: 7

.relative
  position: relative

.padding
  padding: var( --gap )

.padding-x
  padding: 0 var( --gap )

.w-200x
  width: 200px

.w-300x
  width: 300px

.w-400x
  width: 400px

.overflow-y
  overflow-y: auto

.overflow-x
  overflow-x: auto

.overflow-hidden
  overflow: hidden

.centered-items
  align-items: center
